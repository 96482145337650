import React from "react"
import Layout from "../components/layout"
import {Link} from "gatsby"
import img from "../../static/sent-icon.svg"

export default class Index extends React.Component {
    state = {
    }


    render() {
        const s = this.state

        return (
            <Layout className="home">
                <div className="success">
                    <h2>Dziękujemy za kontakt!</h2>
                    <p>Postaramy się jak najszybciej odpowiedzieć<br/> na Twoją wiadomość.</p>
                    <img src={img} alt="" />
                    <Link to="/" className="btn">Powrót do strony głównej</Link>
                    <i className="icon-paper-plane"></i>
                </div>
            </Layout>
        )
    }
}